import $ from 'jquery';
import Cookies from 'js-cookie';
import Navbar from '../../components/navbar';
import { Breakpoint } from '../../types/breakpoint';
import { getCurrentBreakpoint } from '../../utils/breakpoint';
export default class NavbarPromo {
    static $element = $('.js__navbar-promo');
    static $closeLink = $('.js__navbar-promo-close-link');
    static $closeBtn = $('.js__navbar-promo-close-btn');
    static cookieKey = NavbarPromo.$element.data('cookie-key');
    static cookieExpirationDays = 14;
    static hideOnLinkClick = NavbarPromo.$element.data('hide-on-link-click');
    static hideOnCloseClick = NavbarPromo.$element.data('hide-on-close-click');
    static TARGET_LINK = 'link';
    static TARGET_CLOSE_BTN = 'close_btn';
    static init() {
        if (!this.$element.length) {
            return;
        }
        this.bindEvents();
        this.setStickyTopBarPosition();
        this.setPusherTopPosition();
        $(window).on('resize', () => {
            this.setStickyTopBarPosition();
            this.setPusherTopPosition();
        });
    }
    static getNavBarPromoHeight() {
        return this.$element.outerHeight();
    }
    static bindEvents() {
        this.$closeLink.on('click', () => {
            this.close(this.TARGET_LINK);
        });
        this.$closeBtn.on('click', () => {
            this.close(this.TARGET_CLOSE_BTN);
        });
    }
    static close(closeTarget) {
        if ((closeTarget === this.TARGET_LINK && this.hideOnLinkClick) ||
            (closeTarget === this.TARGET_CLOSE_BTN && this.hideOnCloseClick)) {
            Cookies.set(this.cookieKey, '1', { expires: this.cookieExpirationDays });
        }
        //Need timeout to init GA tracking events before removing element
        setTimeout(() => {
            this.$element.remove();
            $(document.body).removeClass('navbar-promo-active');
            this.setStickyTopBarPosition();
            this.setPusherTopPosition();
        });
    }
    static setStickyTopBarPosition() {
        const $stickyBarTop = $('.js__sticky-bar-top');
        const $navBarPromo = $('.js__navbar-promo');
        const $showElement = $('.js__show-on-navbar-promo-height-calculated');
        const navbarHeight = Navbar.getNavbarHeight() || 0;
        const navbarPromoHeight = this.getNavBarPromoHeight() || 0;
        const isStickyMobileBottom = $stickyBarTop.data('sticky-mobile-bottom');
        const currentBreakpoint = getCurrentBreakpoint();
        $stickyBarTop.removeClass('opacity-0');
        $showElement.removeClass('opacity-0');
        if (isStickyMobileBottom && currentBreakpoint < Breakpoint.lg) {
            $stickyBarTop.css({ bottom: 0 });
            $stickyBarTop.css({ top: 'initial' });
        }
        else {
            $stickyBarTop.css('top', $navBarPromo.length ? navbarHeight + navbarPromoHeight : '');
            $stickyBarTop.css({ bottom: 'initial' });
        }
    }
    static setPusherTopPosition() {
        const navbarPromoHeight = this.getNavBarPromoHeight() || 0;
        const defaultPromoHeight = 50;
        const heightDifference = navbarPromoHeight - defaultPromoHeight;
        $('body').css('marginTop', heightDifference >= 0 ? heightDifference : 0);
    }
}
